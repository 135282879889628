<template>
  <div class="w-full md:w-5/10 lg:w-3/10 xl:w-3/10 mx-auto md:pt-20">

    <div class="w-full md:w-8/10 xl:w-7/10 mx-auto">
      <register-credit-card :first_name="first_name" :last_name="last_name" class="mb-16" />
    </div>

    <div class="text-center mb-16">
      <h2 class="mb-5">Great!</h2>
      <div>
        You have completed your Credpal Business Application.
      </div>
    </div>
    
    <div class="flex items-start mb-10">
      <ion-icon name="alert-circle" class="mr-1 text-2xl text-blue-500 w-40px" />
      <div class="ml-2 text-10">
        Your application is being reviewed and will be processed within 2–3 working days.
      </div>
    </div>

    <a :href="landingPageLink" class="btn btn-blue btn-md w-full">
      Back to homepage
    </a>

  </div>
</template>

<script>
  export default {
    props: {
      first_name: {
        type: String,
        default: ''
      },
      last_name: {
        type: String,
        default: ''
      },
    },
    computed: {
      landingPageLink() {
        return 'https://credpal.com';
      }
    }
  }
</script>