<template>
  <div class="w-full md:w-5/10 lg:w-4/10 xl:w-3/10 mx-auto">

    <div class="mb-10 md:text-center">
      <h5 class="mb-4">Business Admin</h5>
      <div>Tell us about yourself</div>
    </div>

    <form @submit.prevent="submit">

      <template v-if="getFormError(baseForm)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(baseForm) }}</span>
        </div>
      </template>

      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-1">
          <form-group
            label-style="grey"
            name="first_name"
            :form="form"
            v-model="form.data.first_name.value"
          >
            First Name
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            label-style="grey"
            name="last_name"
            :form="form"
            v-model="form.data.last_name.value"
          >
            Last Name
          </form-group>
        </div>
      </div>
      <form-group
        label-style="grey"
        name="workplace_email"
        :form="form"
        v-model="form.data.workplace_email.value"
        :optional="true"
      >
        What's your work email address?
      </form-group>
    
      <form-group
        label-style="grey"
        name="email"
        :form="form"
        v-model="form.data.email.value"
      >
        What's your personal email address?
      </form-group>    

      <form-group
        label-style="grey"
        name="phone"
        :form="form"
        v-model="form.data.phone.value"
      >
        What's your phone number?
      </form-group>    

      <form-group
        label-style="grey"
        name="agent_code"
        :form="form"
        v-model="form.data.agent_code.value"
      >
        Reffered by an agent? Insert agent code (Optional) 
      </form-group>    

      <button type="submit" class="btn btn-blue btn-md w-full relative" :disabled="baseForm.loading">
        <sm-loader class="sm-loader-white" v-if="baseForm.loading" />
        <span v-else>Next</span>
        <ion-icon name="arrow-forward-outline" class="absolute" style="top: 50%; right: 20px; transform: translateY(-50%);" />
      </button>

    </form>

  </div>
</template>

<script>
  export default {
    props: {
      baseForm: {
        type: Object
      }
    },
    data() {
      return {
        form: this.$options.basicForm([
          { name: 'first_name', rules: 'required|alpha' },
          { name: 'last_name', rules: 'required|alpha' },
          { name: 'workplace_email', rules: 'required|email' },
          { name: 'email', rules: 'required|email' },
          { name: 'phone', rules: 'required|phone' },
          { name: 'agent_code', rules: 'nullable' }
        ])
      }
    },
    mounted() {
      this.mapForm();
    },
    watch: {
      'baseForm.error'(error) {
        if (error) {
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      }
    },
    methods: {
      mapForm() {
        for (let key in this.form.data) {
          this.form.data[key].value = this.baseForm.data[key].value;
        }

        this.mapFormErrors(this.form, this.baseForm.error?.response?.data?.errors);
      },
      submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.$emit('two', this.getFormData());
      }
    }
  }
</script>