<template>
  <div class="w-full md:w-5/10 lg:w-4/10 xl:w-3/10 mx-auto">

    <div class="mb-10 md:text-center">
      <h5 class="mb-4">Register your company</h5>
      <div>Let’s get you started with your Credpal business account</div>
    </div>

    <form @submit.prevent="submit">

      <template v-if="getFormError(baseForm)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(baseForm) }}</span>
        </div>
      </template>

      <form-group
        label-style="grey"
        name="company_name"
        id="company_name"
        :form="form"
        v-model="form.data.company_name.value"
      >
        What's the name of your company?
      </form-group>
    
      <form-group
        label-style="grey"
        name="company_email_domain"
        id="company_email_domain"
        :form="form"
        v-model="form.data.company_email_domain.value"
      >
        What's your company's email domain?
      </form-group>
    
      <form-group
        label-style="grey"
        name="company_official_email"
        id="company_official_email"
        :form="form"
        v-model="form.data.company_official_email.value"
        :optional="true"
      >
        What's the official email address of your company?
      </form-group>

      <form-group
        label-style="grey"
        name="company_website"
        id="company_website"
        :form="form"
        v-model="form.data.company_website.value"
        :optional="true"
      >
        What is your company's website? (Optional) 
      </form-group>
    
      <form-group
        label-style="grey"
        type="select"
        :options="form.industries"
        placeholder="Select Industry"
        name="company_industry"
        id="company_industry"
        :form="form"
        v-model="form.data.company_industry.value"
      >
        What's your company's industry?
      </form-group>
    
      <form-group
        label-style="grey"
        name="company_address"
        id="company_address"
        :form="form"
        v-model="form.data.company_address.value"
      >
        What's your company's HQ address?
      </form-group>
    
      <!-- <form-group
        label-style="grey"
        name="company_executives"
        id="company_executives"
        :form="form"
        v-model="form.data.company_executives.value"
      >
        How many exetutives does your company have?
      </form-group> -->
    
      <form-group
        label-style="grey"
        name="company_staff"
        id="company_staff"
        :form="form"
        v-model="form.data.company_staff.value"
        class="mb-10"
      >
        How many staff does your company have?
      </form-group>
      
      <button type="submit" class="btn btn-blue btn-md w-full relative" :disabled="baseForm.loading">
        <span v-if="baseForm.loading">Loading</span>
        <span v-else>Next</span>
        <ion-icon name="arrow-forward-outline" class="absolute" style="top: 50%; right: 20px; transform: translateY(-50%);" />
      </button>

      <div class="text-center text-xs mt-5 md:hidden">
        <span class="mr-1 opacity-50">Already have an account?</span>
        <router-link :to="{ name: 'login' }" class="font-medium underline">Sign In</router-link>
      </div>

    </form>

  </div>
</template>

<script>
  export default {
    props: {
      baseForm: {
        type: Object
      }
    },
    data() {
      return {
        form: this.$options.basicForm([
          'company_name',
          'company_email_domain',
          { name: 'company_official_email', rules: 'required|email' },
          { name: 'company_website', rules: 'nullable' },
          'company_industry',
          'company_address',
          // 'company_executives',
          'company_staff'
        ], {
          industries: [
            'Advertising', 'Agriculture Industry',
            'Communications Industry', 'Construction Industry', 'Creative Industries',
            'Education', 'Entertainment Industry',
            'Farming', 'Fashion', 'Finance',
            'Green Industry', 'Heavy Industry', 'Hospitality Industry',
            'Information Industry', 'Information Technology', 'Infrastructure', 'IT Industry',
            'Light Industry', 'Manufacturing', 'Materials', 'Media', 'Music Industry',
            /*'Primary Industry', */'Publishing',
            'Retail', 'Robotics',
            /*'Secondary Industry', */'Service Industry', 'Space', 'Space Industry',
            'Technology Industry', 'Telecom'/*, 'Tertiary Sector'*/
          ]
        })
      }
    },
    mounted() {
      this.mapForm();
    },
    watch: {
      'baseForm.error'(error) {
        if (error) {
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      }
    },
    methods: {
      mapForm() {
        for (let key in this.form.data) {
          this.form.data[key].value = this.baseForm.data[key].value;
        }

        this.mapFormErrors(this.form, this.baseForm.error?.response?.data?.errors);
      },
      submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.$emit('one', this.getFormData());
      }
    }
  }
</script>