<template>
  <div class="pt-24 py-32 px-6 md:px-0" style="background: #FBFBFE">

    <a :href="landingPageLink" class="absolute left-6 md:left-20 top-6 xl:ml-10"><logo /></a>

    <component
      :is="component"
      :base-form="form"
      @one="one"
      @two="two"
      :first_name="form.data.first_name.value"
      :last_name="form.data.last_name.value"
      ref="component"
    />

  </div>
</template>

<script>
  export default {
    name: 'RegisterBusiness',
    components: {
      'business-form': require('./forms/Business').default,
      'personal-form': require('./forms/Personal').default,
      'success': require('./Success').default
    },
    data() {
      return {
        stage: 'business',
        form: this.$options.basicForm([
          'company_name',
          'company_email_domain',
          'company_official_email',
          'company_website',
          'company_industry',
          'company_address',
          // 'company_executives',
          'company_staff',
          'first_name',
          'last_name',
          'workplace_email',
          'email',
          'phone',
          'agent_code',
        ]),
      }
    },
    computed: {
      component() {
        if (this.stage.match(/business|personal/)) {
          return `${this.stage}-form`;
        }

        return this.stage;
      },
      landingPageLink() {
        return 'https://credpal.com';
      }
    },
    methods: {
      one() {
        const form = this.$refs.component.form;
        for (let key in form.data) {
          this.form.data[key].value = form.data[key].value;
        }

        this.stage = 'personal';
      },
      two() {
        const form = this.$refs.component.form;
        for (let key in form.data) {
          this.form.data[key].value = form.data[key].value;
        }

        this.submit();
      },
      async submit() {
        this.form.loading = true;
        this.form.error = null;

        await this.sendRequest('registration.business', {
          data: this.getFormData(),
          success: () => {
            this.stage = 'success';
          },
          error: error => {

            if (error?.response?.data?.errors) {
              if (['company_name', 'company_email_domain', 'company_official_email', 'company_website', 'company_industry', 'company_address', /*'company_executives', */'company_staff'].some(key => error?.response?.data?.errors[key])) {
                this.stage = 'business';
              }else if (['first_name', 'last_name', 'workplace_email', 'email', 'phone', 'agent_code'].some(key => error?.response?.data?.errors[key])) {
                this.stage = 'personal';
              }
            }

            this.form.error = error;
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>