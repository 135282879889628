<template>
  <div></div>
</template>
<script>
import Cookies from "vue-cookies";
export default {
  computed: {
    affiliateCode() {
      return this.$route.query.ac;
    },
    sourceCode() {
      return this.$route.query.me;
    },
  },
  beforeMount() {
    this.saveCode();
  },
  methods: {
    redirectTo() {
      return this.$router.push({
        name: "register",
      });
    },
    saveCode() {
      Cookies.set("affiliateCode", this.affiliateCode, '1h')
      Cookies.set("medium", this.sourceCode, '1h')
      this.redirectTo();
    },
  },
};
</script>
