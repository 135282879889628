<template>
  <div class="grid grid-cols-1 md:grid-cols-12 xl:grid-cols-11">
    <div
      class="absolute h-75px top-0 left-0 right-0 w-full bg-white"
      style="z-index: 1"
    />
    <a
      :href="landingPageLink"
      class="absolute left-6 md:left-20 top-6 xl:ml-10 z-10"
    >
      <logo />
    </a>

    <div
      class="
        col-span-1 md:col-span-6 xl:col-span-5
        xl:col-start-1 px-6 py-10 duration-300
      "
    >
      <div class="md:pt-24">
        <div class="w-full lg:w-8/10 xl:w-7/10 mx-auto flex flex-col relative">
          <div class="hidden md:block">
            <template v-if="first_name">
              <div class="mb-10 duration-300">
                Hello <span class="font-black">{{ first_name }}</span>
              </div>
            </template>
            <template v-else>
              <div class="mb-0 duration-300" />
            </template>

            <h1 class="-mt-1 mb-12 font-bold">Let’s get your card ready!</h1>
        
            <register-credit-card
              :first_name="first_name"
              :last_name="last_name"
              class="mb-16 xl:w-8/10"
            />

            <div class="mb-10 mt-50px text-sm">
              By creating an account,
              I confirm that I have read and understood the 
              <a
                :href="`${landingPageLink}/privacy-policy`"
                class="font-black text-black underline"
              >
                CredPal Privacy Policy
              </a>
              and 
              <a
                :href="`${landingPageLink}/terms`"
                class="font-black text-black underline"
              >
                Terms of Use
              </a>. 
            </div>

            <div>
              <span class="mr-1">Already have an account?</span>
              <router-link
                :to="{ name: 'login' }"
                class="font-black text-blue-500 underline"
              >
                Sign In
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        col-span-1 md:col-span-6 xl:col-span-5 xl:col-start-7
        px-6 py-10 md:pl-0 pt-30px md:pt-10 h-screen
        overflow-y-auto pb-300px md:pb-20
      "
    >
      <div class="w-full md:w-8/10 lg:w-7/10 xl:w-6/10 md:ml-0 xl:ml-0">
        <register-form
          class="pb-75px"
          @name-changed="setName"
          @register="authenticateUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  export default {
    components: {
      'register-form': require('./forms/Index.vue').default,
    },
    data() {
      return {
        first_name: null,
        last_name: null,
      }
    },
    computed: {
      landingPageLink() {
        return 'https://credpal.com';
      },
    },
    methods: {
      ...mapActions('session', ['login']),
      setName({ first_name, last_name }) {
        this.first_name = first_name;
        this.last_name = last_name;
      },
      authenticateUser(data) {
        const { token, user, roles, permissions, keys } = data;
        this.login({
          token,
          user,
          roles,
          keys,
          permissions: permissions.map(permission => permission.slug),
        })
        .then(() => {
          this.$router.push({ name: 'root' });
        });
      },
    }
  }
</script>
