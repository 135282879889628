<template>
  <form class="md:pt-24" @submit.prevent="submit">
    <h6 class="font-bold mb-16">Create Account</h6>

    <template v-if="getFormError(form)">
      <div class="alert alert-red-soft mb-10">
        <span class="alert-icon">!</span>
        <span>{{ getFormError(form) }}</span>
      </div>
    </template>

    <p class="font-bold mb-5">Basic information</p>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="col-span-1">
        <form-group
          id="first_name"
          v-model="form.data.first_name.value"
          name="first_name"
          :form="form"
        >
          First Name
        </form-group>
      </div>
      <div class="col-span-1">
        <form-group
          id="last_name"
          v-model="form.data.last_name.value"
          name="last_name"
          :form="form"
        >
          Last Name
        </form-group>
      </div>
    </div>

    <form-group
      id="phone_no"
      v-model="form.data.phone_no.value"
      name="phone_no"
      :form="form"
    >
      Phone number
    </form-group>

    <form-group
      v-model="form.data.employment_status.value"
      type="select"
      :options="form.employmentStatuses"
      placeholder="Select Employment Status"
      name="employment_status"
      :form="form"
    >
      Employment Status
    </form-group>

    <div
      class="relative mb-6"
    >
      <form-group-old
        id="employer"
        v-model="form.data.employer.value"
        name="employer"
        placeholder=""
        :form="form"
        autocomplete="off"
        class="mb-0"
        @input="getCompanies"
        @focus="companies.isFocused = true"
        @blur="blur"
      >
        Workplace
      </form-group-old>
      <div
        v-show="canShowDropdown"
        class="absolute bg-white border border-blue-200 max-h-300px overflow-y-auto rounded-sm shadow-lg top-full w-full z-10"
      >
        <div v-if="companies.loading" class="px-5 py-3">
          <sm-loader />
        </div>
        <template v-else>
          <template v-for="(company, index) in companies.data">
            <button
              :key="index"
              href="#!"
              class="dropdown-menu-item outline-none w-full focus:outline-none"
              @click="selectCompany(company.name)"
            >
              {{ company.name }}
            </button>
          </template>
        </template>
      </div>
    </div>

    <form-group
      id="salary"
      v-model="form.data.salary.value"
      type="money"
      name="salary"
      :precision="0"
      placeholder="How much is your current salary?"
      :form="form"
    >
      Monthly salary / income
    </form-group>

    <form-group
      v-model="form.data.referral_code.value"
      type="text"
      name="referral_code"
      :form="form"
    >
      Referral Code (Optional)
    </form-group>

    <p class="font-bold mb-5">Account security</p>

    <form-group
      id="email"
      v-model="form.data.email.value"
      name="email"
      :form="form"
      autocomplete="username"
    >
      Email address
    </form-group>

    <form-group
      id="password"
      v-model="form.data.password.value"
      type="password"
      name="password"
      :form="form"
      autocomplete="current-password"
    >
      Password
    </form-group>

    <button
      type="submit"
      class="btn btn-blue btn-md w-full relative"
      :disabled="form.loading"
    >
      <sm-loader v-if="form.loading" class="sm-loader-white" />
      <span v-else>Next</span>
      <ion-icon
        name="arrow-forward-outline"
        class="absolute"
        style="top: 50%; right: 20px; transform: translateY(-50%);"
      />
    </button>

    <div class="text-center text-xs mt-5 md:hidden">
      <span class="mr-1 opacity-50">Already have an account?</span>
      <router-link :to="{ name: 'login' }" class="font-medium underline">
        Sign In
      </router-link>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import Cookies from "vue-cookies";

export default {
  data() {
    return {
      companies: this.$options.resource([], {
        isFocused: false,
        meta: {}
      }),
      form: this.$options.basicForm([
        { name: 'first_name', rules: 'required' },
        { name: 'last_name', rules: 'required' },
        { name: 'phone_no', rules: 'required|phone'},
        { name: 'employment_status', rules: 'required' },
        { name: 'employer', rules: 'required' },
        { name: 'salary', rules: 'required|number' },
        { name: 'email', rules: 'required|email' },
        { name: 'password', rules: 'required|min:8' },
        { name: "referral_code", rules: "nullable" },
      ], {
        employmentStatuses: [
          { title: "Employed", value: "employed" },
          { title: "Self Employed", value: "self_employed" },
        ],
      }),
      request: null,
    };
  },
  computed: {
    affiliateCode() {
      const value = Cookies.get('affiliateCode')
      return value ?? '';
    },
    canShowDropdown() {
      return this.companies.isFocused && (this.companies.data?.length || this.companies.loading);
    },
    firstName() {
      return this.form.data.first_name.value;
    },
    lastName() {
      return this.form.data.last_name.value;
    },
    medium() {
      const value = Cookies.get('medium')
      return value ?? '';
    },
    name() {
      return `${this.firstName} ${this.lastName}`;
    },
    utmCampaign() {
      const value = Cookies.get("utmCampaign");
      return value ?? "";
    },
    utmMedium() {
      const value = Cookies.get("utmMedium");
      return value ?? "";
    },
  },
  mounted() {
    this.referralCode();
  },
  watch: {
    name() {
      this.$emit('name-changed', this.getFormData());
    }
  },
  methods: {
    blur() {
      setTimeout(() => {
        this.companies.isFocused = false
      }, 100);
    },
    cancelRequest() {
      this.request.cancel();
    },
    referralCode() {
      const value = Cookies.get("referralCode");
      if (value) {
        this.form.data.referral_code.value = value;
      }
      return value ?? "";
    },
    removeCookieCodes() {
      Cookies.remove("medium");
      Cookies.remove("affiliateCode");
      Cookies.remove("referralCode");
      Cookies.remove("utmCampaign");
      Cookies.remove("utmMedium");
    },
    selectCompany(companyName) {
      this.form.data.employer.value = companyName;
      this.companies.setValue([]);
    },
    async getCompanies() {
      this.request?.cancel();

      // Somehow the request doesn't cancel before the next one begins.
      // This should help prevent that from happening.
      await new Promise(resolve => setTimeout(resolve, 10));

      const companyName = this.form.data.employer.value;
      if (companyName === '') {
        this.companies.setValue([]);
        return;
      }

      const axiosSource = axios.CancelToken.source();

      this.request = { cancel: axiosSource.cancel };

      this.companies.loading = true;
      await this.sendRequest('registration.whitelistedCompanies', companyName, {
        axiosProps: {
          cancelToken: axiosSource.token,
        },
        success: (response) => {
          const { data: { results, count, next, previous } } = response.data;
          this.companies.setValue(results);
          this.companies.meta = { count, next, previous };
        },
        error: (error) => this.companies.error = error,
      });
      this.companies.loading = false;
    },
    async submit() {
      //this.googleEvent("Sign Up", "create_account")

      if (!this.validateForm(this.form)) {
        return false;
      }
      this.form.loading = true;
      this.form.error = false;
      let formValues = this.getFormData();

      if (this.$route.query.source) {
        formValues = { ...this.getFormData(), source: "monieoo" };
      }

      if (this.affiliateCode && this.medium) {
        formValues = {
          ...formValues,
          affiliate_code: this.affiliateCode,
          medium: this.medium,
        };
      }

      if (this.utmCampaign && this.utmMedium) {
        formValues = {
          ...formValues,
          utm_campaign: this.utmCampaign,
          utm_medium: this.utmMedium,
        };
      }

      await this.sendRequest("registration.createAccount", {
        data: {
          ...formValues
        },
        success: (response) => {
          const { data } = response.data;
          this.$emit('register', data);
          this.removeCookieCodes();
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });

      this.form.loading = false;
    },
  },
}
</script>
